import React from "react";

import { Link } from 'gatsby';

import classNames from "classnames";

import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import featureStyle from "assets/jss/material-kit-react/views/landingPageSections/featureStyle.jsx";



class FormatHere extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer className={classes.featureGrey}>
          <GridItem xs={12} lg={3}>
              <div className={classes.featureRightInfo}>
              <h2 className={classes.title}>Format Here</h2>


              <h4 className={classes.description}>
                We really swung for the fences here.
              </h4>
              <h4 className={classes.description}>
                 Format your manuscript using a powerful point and click interface.
              </h4>
              <h4 className={classes.description}>
                Start with a theme. Then make all the changes that you require.
              </h4>
              <h4 className={classes.description}>
                Once you have completed your formatting, optionally save all of these specific settings as a custom style that you can reuse for your next book.
              </h4>
              <h4 className={classes.description}>
                You can also share this custom style with other authors that you have connected with in BookMerlin. And they can share their custom styles with you.
              </h4>
                </div>
            </GridItem>
          <GridItem xs={12} lg={9}className={classes.exampleGrid}>
            <div className={classes.exampleImageLeft}>
                  <img
                      src={require('pages/HomePage/img/features_formatting4.jpg')}
                       className={classes.featureLeftImg}/>
            </div>
          </GridItem>

        </GridContainer>
      </div>
    );
  }
}

export default withStyles(featureStyle)(FormatHere);
